import { extend, merge, trimEnd, noop, get } from 'lodash';
import URI from 'urijs';

const appendPetriOvr = (path, petriOvr) => {
  if (!petriOvr) {
    return path;
  }
  const uri = new URI(path);
  uri.setQuery('petri_ovr', petriOvr);
  return URI.decode(uri);
};

export default function createRequest({
  baseUrl = '',
  getInstance = () => null,
  locale = 'en',
  groupId,
  cookie,
  performanceTracker = { trackStart: noop, trackEnd: noop },
  siteRevision,
  trackError = () => {},
  logResponse = () => {},
  petriOvr = '',
} = {}) {
  const request = (path, config = {}) => {
    const url = appendPetriOvr(`${trimEnd(baseUrl, '/')}${path}`, petriOvr);

    const shouldParseHeaders = config.parseHeaders;

    delete config.parseHeaders;

    const instance = getInstance();
    const headers = {
      instance,
      Authorization: instance,
      locale,
      ...config.headers,
    };

    if (cookie) {
      headers.cookie = cookie;
    }
    if (groupId) {
      headers['group-id'] = groupId;
    }
    if (siteRevision) {
      headers['x-wix-site-revision'] = siteRevision;
    }

    extend(config, {
      headers,
      credentials: 'same-origin',
    });
    const marker = performanceTracker.trackStart(`${config.method || 'GET'} ${path}`);

    const start = () => fetch(url, config);
    const parse = response =>
      Promise.resolve(response)
        .then(handleSuccess)
        .then(shouldParseHeaders ? parseHeadersAndBody : parseBody)
        .then(r => {
          performanceTracker.trackEnd(marker);
          return r;
        })
        .then(r => {
          const headers = [];
          try {
            if (r && r.headers && r.headers.entries) {
              for (const h of r.headers.entries()) {
                headers.push(h);
              }
            } else if (r && r.headers) {
              headers.push(JSON.stringify(r.headers));
            }
          } catch (error) {
            trackError(error);
          }

          logResponse([path, r && r.body ? r.body : r, headers]);
          return r;
        })
        .catch(error => {
          trackError(
            `request error: path=${path}, status=${get(error, 'status')}, error=${JSON.stringify(
              error,
            )}`,
          );
          return Promise.reject(error);
        });

    const sleep = timeout => new Promise(resolve => setTimeout(resolve, timeout));
    const retry = (fn, retries = 3, timeout = 300) =>
      new Promise((resolve, reject) =>
        fn().then(
          response => {
            if (response.status >= 500 && retries > 1) {
              return sleep(timeout)
                .then(() => retry(fn, retries - 1, timeout * 2))
                .then(resolve, reject);
            }
            resolve(response);
          },
          error => {
            if (retries > 1) {
              return sleep(timeout)
                .then(() => retry(fn, retries - 1, timeout * 2))
                .then(resolve, reject);
            }
            reject(error);
          },
        ),
      );

    return retry(start).then(parse);
  };

  request.post = (path, data, config) => {
    return request(
      path,
      merge({}, config, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    );
  };

  request.put = (path, data, config) => {
    return request(
      path,
      merge({}, config, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    );
  };

  request.patch = (path, data, config) => {
    return request(
      path,
      merge({}, config, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      }),
    );
  };

  request.delete = (path, config) => {
    return request(
      path,
      extend({}, config, {
        method: 'DELETE',
      }),
    );
  };

  request.uploadFile = (file, credentials) => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('media_type', 'picture');
    formData.append('upload_token', credentials.uploadToken);
    formData.append('parent_folder_id', credentials.folderId);

    const config = {
      method: 'POST',
      body: formData,
    };

    return fetch(credentials.uploadUrl, config)
      .then(handleSuccess)
      .then(parseBody);
  };

  return request;
}

function handleSuccess(response) {
  const status = response.status;

  if (status < 400) {
    return response;
  }

  return Promise.reject(response);
}

function parseBody(response) {
  return response.json().catch(() => Promise.resolve()); // catch is incase it's not json
}

function parseHeadersAndBody(response) {
  return response
    .json()
    .then(data => ({
      body: data,
      headers: response.headers,
    }))
    .catch(() => Promise.resolve()); // catch is incase it's not json
}
