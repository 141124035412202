export const REPORT_POST_REQUEST = 'post/REPORT_REQUEST';
export const REPORT_POST_SUCCESS = 'post/REPORT_SUCCESS';
export const REPORT_POST_FAILURE = 'post/REPORT_FAILURE';

export function reportPost(report = {}) {
  return (dispatch, getState, { request }) => {
    dispatch({
      type: REPORT_POST_REQUEST,
    });

    const promise = request.post(`/posts/${report.postId}/report`, {
      reportType: report.reportType,
    });

    return promise
      .then(() =>
        dispatch({
          type: REPORT_POST_SUCCESS,
          payload: {
            _id: report.postId,
          },
        }),
      )
      .catch(() =>
        dispatch({
          type: REPORT_POST_FAILURE,
        }),
      )
      .then(() => promise);
  };
}
