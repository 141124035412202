export const INCREMENT_POST_LIKE_COUNT_REQUEST = 'post/INCREMENT_LIKE_COUNT_REQUEST';
export const INCREMENT_POST_LIKE_COUNT_SUCCESS = 'post/INCREMENT_LIKE_COUNT_SUCCESS';
export const INCREMENT_POST_LIKE_COUNT_FAILURE = 'post/INCREMENT_LIKE_COUNT_FAILURE';

export const incrementPostLikeCountRequest = postId => ({
  type: INCREMENT_POST_LIKE_COUNT_REQUEST,
  payload: { _id: postId },
});

export const incrementPostLikeCountFailure = postId => ({
  type: INCREMENT_POST_LIKE_COUNT_FAILURE,
  payload: { _id: postId },
});

export const incrementPostLikeCountSuccess = (postId, { count, isLiked }) => ({
  type: INCREMENT_POST_LIKE_COUNT_SUCCESS,
  payload: { _id: postId, likeCount: count, isLiked },
});

export function incrementPostLikeCount(postId) {
  return (dispatch, getState, { request }) => {
    dispatch(incrementPostLikeCountRequest(postId));
    const promise = request.post(`/posts/${postId}/likes`);
    return promise
      .then(
        data => dispatch(incrementPostLikeCountSuccess(postId, data)),
        () => dispatch(incrementPostLikeCountFailure(postId)),
      )
      .then(() => promise);
  };
}
