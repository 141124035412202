class BiLocationTracker {
  constructor() {
    this.state = {};
  }

  set = (location, action) => (this.state[action] = { location, timestamp: Date.now() });

  get = action =>
    this.state[action] && Date.now() - this.state[action].timestamp < 1000
      ? this.state[action].location
      : null;
}

export default new BiLocationTracker();
