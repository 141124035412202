import {
  FORUM_DATA_CATEGORIES_MANAGER_ID,
  TEMPLATE_CATEGORY_ID,
  TEMPLATE_CATEGORY_GROUP_ID,
} from '../constants/categories-manager';
import { omit, isString } from 'lodash';
import { COVER_TYPE_COLOR } from '../constants/cover-types';
import { CATEGORY_TYPE_PUBLIC, CATEGORY_TYPE_MEMBERS_ONLY } from '../constants/category-types';
import { isPrivate, isMembersOnly } from '../../common/services/category-privacy-utils';
import { DISCUSSION, QUESTION } from '../constants/post-types';

export const OMIT_FIELDS = [
  '_id',
  'instanceId',
  'totalPosts',
  'totalViews',
  'isSubscribed',
  'slug',
  'slugs',
  'categoryGroupId',
];

export const omitProhibitedUpdateFields = category => omit(category, OMIT_FIELDS);

export const createCategoryTemplate = ({ description, label, rank }) => ({
  coverType: COVER_TYPE_COLOR,
  description,
  label,
  headerTitle: label,
  rank,
  totalPosts: 0,
  totalViews: 0,
  categoryGroupId: TEMPLATE_CATEGORY_GROUP_ID,
  _id: TEMPLATE_CATEGORY_ID,
  postTypes: [DISCUSSION, QUESTION],
});

export const isForumData = categoryId => categoryId === FORUM_DATA_CATEGORIES_MANAGER_ID;

// older categories won't have headerTitle property so we take label
export const getHeaderTitle = category =>
  isString(category.headerTitle) ? category.headerTitle : category.label;

export const decorateCategoryWithType = category => {
  if (category && !category.type) {
    category.type = isMembersOnly(category) ? CATEGORY_TYPE_MEMBERS_ONLY : CATEGORY_TYPE_PUBLIC;
  }
  return category;
};

export const isMultiplePostTypeCategory = (category = {}) => {
  const allPostsCategory = !category.postTypes;
  return Boolean(category.postTypes && category.postTypes.length > 1) || allPostsCategory;
};

export const getSubcategories = (allCategories, categoryId) =>
  allCategories.filter(c => c.parentId === categoryId);
