import { get } from 'lodash';
import { getWixP } from '../../selectors/wix-params-selectors';

export const getAppSettings = state => state.appSettings;

export const getAppSettingsValue = ({ style, key, fallback, state, getMobileSettingsValue }) =>
  get(
    style,
    getWixP(key, getMobileSettingsValue !== undefined ? getMobileSettingsValue : state),
    get(style, getWixP(key, false), fallback),
  );

export const getCustomAppSettings = state =>
  getAppSettingsValue({ state, key: 'custom', fallback: {} });
