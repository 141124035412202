import { flowRight, map, toString, chain, identity, trim, uniq } from 'lodash';
import hashtagRegexes from './hashtag-regexes';

function extractHashtags(text) {
  if (!text || !text.match(hashtagRegexes.hashSigns)) {
    return [];
  }

  const tags = [];

  //eslint-disable-next-line max-params
  text.replace(hashtagRegexes.validHashtag, (match, before, hash, hashText, offset, chunk) => {
    const after = chunk.slice(offset + match.length);
    if (after.match(hashtagRegexes.endHashtagMatch)) {
      return;
    }
    const startPosition = offset + before.length;
    const endPosition = startPosition + hashText.length + 1;
    tags.push({
      hashtag: hashText,
      indices: [startPosition, endPosition],
    });
  });

  return tags;
}

const getContentText = content =>
  chain(content)
    .get('blocks', [])
    .filter(block => block.type !== 'code-block')
    .map(block => trim(block.text))
    .filter(identity)
    .join(' ')
    .value();

const mapHashtags = hashtags => map(hashtags, hashtagData => hashtagData.hashtag);
const mapToLowerCase = hashtags => map(hashtags, hashtag => hashtag.toLowerCase());

export const extractHashtagsFromText = flowRight(
  uniq,
  mapToLowerCase,
  mapHashtags,
  extractHashtags,
  toString,
);

export const extractHashtagsFromContent = flowRight(
  uniq,
  mapToLowerCase,
  mapHashtags,
  extractHashtags,
  getContentText,
);
