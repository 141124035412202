import requestCredentials from '../../../common/services/wix-media/media-api';
import uploadFile from '../../../common/services/wix-media/upload-file';
import { TYPE_IMAGE } from '../../../common/services/wix-media/media-types';
import { isOwner } from '../../../common/store/current-user/current-user-selectors';
import { setIsLoading } from '../set-is-loading';
import {
  categoriesManagerUpdateForumDataLocally,
  categoriesManagerUpdateCategoryLocally,
} from '../categories-manager-actions';
import { isForumData } from '../../services/categories-service';
import { addItemToUploadedRegistry } from '../uploaded-registry-actions';
import { uploadEnded } from '../upload-ended';
import { uploadStarted } from '../upload-started';
import { VISITOR_UPLOADS_FOLDER_ID } from '../../constants/upload';

export const uploadImage = (file, imageData, uploadId) => (dispatch, getState, { request }) => {
  const id = uploadId || new Date().getTime();
  const stopLoading = data => {
    dispatch(setIsLoading('upload', id, false));
    return data;
  };
  dispatch(setIsLoading('upload', id, true));

  const folderId = isOwner(getState()) ? null : VISITOR_UPLOADS_FOLDER_ID;
  const payload = file.type !== 'image/gif' ? imageData.dataUrl : file;
  dispatch(uploadStarted({ type: TYPE_IMAGE }));
  return requestCredentials(request, TYPE_IMAGE)
    .then(
      credentials =>
        uploadFile({
          file: payload,
          name: file.name,
          type: TYPE_IMAGE,
          credentials,
          folderId,
        }).promise,
    )
    .then(uploadedImageData => {
      dispatch(addItemToUploadedRegistry({ uploadId, uploadData: uploadedImageData }));
      dispatch(uploadEnded({ type: TYPE_IMAGE }));
      return uploadedImageData;
    })
    .then(stopLoading)
    .catch(stopLoading);
};

export const uploadImageRCE = (file, imageData, uploadId) => dispatch => {
  dispatch(uploadImage(file, imageData, uploadId));
};

export const uploadImageCategoriesManager = (
  file,
  imageData,
  { uploadId, coverType, categoryId },
) => dispatch => {
  const update = isForumData(categoryId)
    ? categoriesManagerUpdateForumDataLocally
    : categoriesManagerUpdateCategoryLocally;
  return dispatch(uploadImage(file, imageData, uploadId)).then(uploadedImageData => {
    dispatch(update({ cover: uploadedImageData, coverType }));
  });
};
