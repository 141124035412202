import { trim } from 'lodash';
import { getRouteParams } from '../../common/router/router-selectors';
import { getLocation } from '../../common/store/location/location-selectors';
import { getCategories } from '../selectors/categories-selectors';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';

export const goBack = () => (dispatch, getState) => {
  const state = getState();
  const categories = getCategories(state);
  const hasOneCategory = categories.length === 1;
  const currentLocation = getLocation(state);

  const parts = trim(currentLocation.pathname, '/').split('/');
  parts.pop();
  if (getRouteParams(state).page) {
    parts.pop();
  }
  if (hasOneCategory && parts.length === 0) {
    parts.push(categories[0].slug);
  }
  const path = '/' + parts.join('/');

  return dispatch(navigateWithinForum(path));
};
