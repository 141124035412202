import { get, find, filter, isEmpty } from 'lodash';
import { createSelector } from 'reselect';
// import sortPosts from '../services/sort-posts';
import { UNCATEGORIZED_POSTS } from '../reducers/pagination';
import { getPostEntityIdsForPage, getPostEntityIds } from './pagination-selectors';

export const getPostMap = state => state.posts || {};
export const getPost = (state, postId) => getPostMap(state)[postId];
const getSlug = (state, slug) => slug;
const getOwnerId = (state, ownerId) => ownerId;
const getSiteMemberId = (state, siteMemberId) => siteMemberId;

export const getPostBySlug = createSelector([getPostMap, getSlug], (posts, slug) =>
  find(posts, post => post.slug === slug || (post.slugs && post.slugs.indexOf(slug) > -1)),
);

export const getSortedPostsByCategoryId = (state, categoryId) => {
  const posts = getPostMap(state);
  return getPostEntityIds(state, categoryId).map(id => posts[id]);
};

export const getSortedAllPosts = state => getSortedPostsByCategoryId(state, UNCATEGORIZED_POSTS);

export const getPostsByCategoryIdAndPage = createSelector(
  [getPostMap, getPostEntityIdsForPage],
  (posts, entityIds) => entityIds.map(id => posts[id]),
);

export const getPostsBySiteMemberId = createSelector(
  [getPostMap, getSiteMemberId],
  (posts, siteMemberId) => filter(posts, post => get(post, 'owner.siteMemberId') === siteMemberId),
);

export const getNewPosts = state => state.footerPosts || [];

export const getRelatedPosts = state => state.relatedPosts || [];

export const hasRelatedPosts = state => !isEmpty(getRelatedPosts(state));

export const getRelatedAndNewPosts = state => {
  let posts = getNewPosts(state);
  posts = [...getRelatedPosts(state), ...posts].slice(0, 3);
  return posts || [];
};
