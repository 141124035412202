import { DUPLEXER_CHANNELS, DUPLEXER_EVENTS } from '../../../../universal/constants/duplexer';
import { updateCategoryCounters, updatePostCounters } from '../../actions/category-socket';
import { newComment } from '../../actions/comment-socket';
import { addTypingUser, removeTypingUserDebounced } from '../../actions/typing-socket';

export default {
  [DUPLEXER_CHANNELS.FORUM]: {
    [DUPLEXER_EVENTS.UPDATE_POST_COUNTERS]: updatePostCounters,
    [DUPLEXER_EVENTS.UPDATE_CATEGORY_COUNTERS]: updateCategoryCounters,
  },
  [DUPLEXER_CHANNELS.POST]: {
    [DUPLEXER_EVENTS.TYPING]: message => dispatch => {
      dispatch(removeTypingUserDebounced(message));
      dispatch(addTypingUser(message));
    },
    [DUPLEXER_EVENTS.NEW_COMMENT]: newComment,
  },
};
