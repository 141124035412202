import { DELETE_POST } from '../../../constants/interactions';
import { POST_DELETE } from '../../messages/message-types';
import { deletePost } from '../../../actions/delete-post';
import { getPost } from '../../../selectors/post-selectors';
import { getCategory } from '../../../selectors/categories-selectors';
import { navigateWithinForum } from '../../../../common/actions/navigate-within-forum';

export const deletePostModalResolver = (postId, { showMessage }) => (
  dispatch,
  getState,
  { fedopsLogger },
) => {
  fedopsLogger.interactionStarted(DELETE_POST);
  const state = getState();
  const post = getPost(state, postId);
  const category = getCategory(state, post.categoryId);
  dispatch(navigateWithinForum(`/${category.slug}`));

  return dispatch(deletePost(postId)).then(() => showMessage(POST_DELETE));
};
