import { every, get, includes, isEmpty, some } from 'lodash';
import AclService from 'js-acl';
import {
  USER_PRIVACY_STATUS_PRIVATE,
  USER_PRIVACY_STATUS_PUBLIC,
} from '../../constants/user-privacy-status';
import { getUserRole } from '../../services/user-role';

export const ROLE_GUEST = 'guest';
export const ROLE_USER = 'user';
export const ROLE_MODERATOR = 'moderator';
export const ROLE_ADMIN = 'admin';
export const ROLE_OWNER = 'owner';

export const RESOURCE_FORUM = 'forum';
export const RESOURCE_CATEGORY = 'category';
export const RESOURCE_POST = 'post';
export const RESOURCE_COMMENT = 'comment';
export const RESOURCE_MEMBER = 'member';

export const PERMISSIONS_ALL = 'all';

/*
 * PermissionsChecker provides 2 types of checks:
 * - can: this checks if a role can do an action on resource
 * - canSee: this checks if a role can see an action on resource (know that there is an ability to do such action)
 *
 * They are mostly identical, but for some resources action they differ. For example,
 * everyone can see like on post, but only logged in, non blocked, non private can like.
 *
 * If a role can do an action on resource, this means he can also see.*/
export function permissionsChecker(
  { isOldForum = false, isMemberAppInstalled = false, isMemberAreaInstalled = false } = {},
  user,
  resolve,
) {
  const hasMembersFunctions = isOldForum || isMemberAppInstalled || isMemberAreaInstalled;

  const setupAcl = (acl, isSee) => {
    // Roles
    acl.addRole('guest');
    // acl.addRole('user', 'guest');
    acl.addRole('user');
    acl.addRole('moderator', 'user');
    acl.addRole('admin', 'user');
    acl.addRole('owner', 'admin');

    // Resources
    acl.addResource('forum');
    acl.addResource('category');
    acl.addResource('post', 'category');
    acl.addResource('comment', 'post');
    acl.addResource('member');

    // Guest
    acl.allow('guest', 'category', 'read', and(not(resourceIsMembersOnly), resourceIsAccesible));
    acl.allow('guest', 'category', 'share');

    if (isSee) {
      if (hasMembersFunctions) {
        acl.allow('guest', 'category', 'subscribe', not(userIsBlocked));
      }
      acl.allow(
        'guest',
        'post',
        ['like', 'vote'],
        and(not(resourceIsMembersOnly), resourceIsAccesible),
      );
    }
    acl.allow('guest', 'member', 'edit', userIsResource);

    // User
    acl.allow('user', 'category', 'share');
    acl.allow(
      'user',
      'category',
      'read',
      and(
        or(resourceIsAccesible, userHasPermission('read'), userHasPermission('write')),
        or(
          and(not(resourceIsMembersOnly), resourceIsAccesible),
          and(resourceIsMembersOnly, not(userIsPrivate)),
          and(resourceIsAccesible, not(userIsPrivate)),
        ),
      ),
    );

    if (hasMembersFunctions) {
      acl.allow(
        'user',
        'category',
        'subscribe',
        isSee
          ? or(resourceIsAccesible, userHasPermission('read'), userHasPermission('write'))
          : and(
              or(resourceIsAccesible, userHasPermission('read'), userHasPermission('write')),
              not(userIsPrivate),
            ),
      );
    }
    acl.allow(
      'user',
      'category',
      'create-post',
      or(and(not(resourceIsWriteProtected), resourceIsAccesible), userHasPermission('write')),
    );
    acl.allow(
      'user',
      'post',
      ['like', 'vote'],
      isSee
        ? or(resourceIsAccesible, userHasPermission('read'), userHasPermission('write'))
        : and(
            or(resourceIsAccesible, userHasPermission('read'), userHasPermission('write')),
            not(userIsPrivate),
          ),
    );
    acl.allow(
      'user',
      'post',
      'edit',
      isSee ? userIsResourceOwner : and(userIsResourceOwner, not(userIsPrivate)),
    );
    acl.allow('user', 'post', 'delete', userIsResourceOwner);
    acl.allow(
      'user',
      'post',
      'create-comment',
      and(
        not(resourceHasCommentsDisabled),
        or(resourceIsAccesible, userHasPermission('read'), userHasPermission('write')),
      ),
    );

    acl.allow(
      'user',
      'post',
      [
        'add-top-comment',
        'remove-top-comment',
        'add-best-answer-comment',
        'remove-best-answer-comment',
      ],
      userIsResourceOwner,
    );

    if (hasMembersFunctions) {
      acl.allow(
        'user',
        'post',
        'report',
        isSee
          ? and(not(userIsResourceOwner), not(resourceOwnerHasRoles('owner')))
          : and(not(userIsResourceOwner), not(resourceOwnerHasRoles('owner')), not(userIsPrivate)),
      );
      acl.allow(
        'user',
        'post',
        'subscribe',
        isSee
          ? or(userHasPermission('read'), userHasPermission('write'))
          : and(or(userHasPermission('read'), userHasPermission('write')), not(userIsPrivate)),
      );
    } else {
      acl.deny('user', 'post', 'subscribe');
    }

    if (hasMembersFunctions) {
      acl.allow(
        'user',
        'comment',
        'report',
        isSee
          ? and(not(userIsResourceOwner), not(resourceOwnerHasRoles('owner')))
          : and(not(userIsResourceOwner), not(resourceOwnerHasRoles('owner')), not(userIsPrivate)),
      );
    }

    acl.allow(
      'user',
      'comment',
      ['like', 'vote'],
      isSee
        ? or(userHasPermission('read'), userHasPermission('write'))
        : and(or(userHasPermission('read'), userHasPermission('write')), not(userIsPrivate)),
    );

    acl.allow('user', 'comment', 'edit', userIsResourceOwner);
    acl.allow('user', 'comment', 'delete', userIsResourceOwner);

    if (hasMembersFunctions) {
      acl.allow('user', 'member', 'subscribe', and(not(userIsResource), not(userIsPrivate)));
      acl.allow('user', 'member', 'list', not(userIsPrivate));
      acl.allow(
        'user',
        'member',
        'report',
        and(and(not(userIsResource), not(resourceHasRoles('owner'))), not(userIsPrivate)),
      );
    }

    acl.allow('user', 'member', 'edit', userIsResource);
    acl.allow('user', 'member', 'delete', and(userIsResource, not(resourceHasRoles('owner'))));

    // Moderator
    acl.allow('moderator', 'category', 'read');
    acl.allow(
      'moderator',
      'category',
      ['create-post', 'edit', 'subscribe'],
      userHasPermission('moderate'),
    );
    acl.allow(
      'moderator',
      'post',
      [
        'pin',
        'toggle-comments',
        'move',
        'create-comment',
        'add-top-comment',
        'remove-top-comment',
        'add-best-answer-comment',
        'remove-best-answer-comment',
        'like',
      ],
      userHasPermission('moderate'),
    );
    acl.allow(
      'moderator',
      'post',
      'delete',
      and(userHasPermission('moderate'), not(resourceOwnerHasRoles('admin', 'owner'))),
    );
    acl.allow(
      'moderator',
      'post',
      'create-comment',
      and(not(resourceHasCommentsDisabled), userHasPermission('moderate')),
    );
    acl.allow('moderator', 'comment', ['like', 'vote'], userHasPermission('moderate'));
    acl.deny('moderator', 'post', 'edit', not(userIsResourceOwner));
    acl.deny('moderator', 'post', 'report', userHasPermission('moderate'));
    acl.deny('moderator', 'comment', 'report', userHasPermission('moderate'));

    // Admin
    acl.allow('admin', 'forum', 'reorder-categories');
    acl.allow('admin', 'category', [
      'read',
      'subscribe',
      'create',
      'create-post',
      'edit',
      'delete',
    ]);
    acl.allow('admin', 'post', [
      'pin',
      'toggle-comments',
      'move',
      'create-comment',
      'delete',
      'add-top-comment',
      'remove-top-comment',
      'add-best-answer-comment',
      'remove-best-answer-comment',
      'like',
      'vote',
    ]);
    acl.allow('admin', 'post', 'create-comment', not(resourceHasCommentsDisabled));
    acl.allow('admin', 'comment', ['delete', 'like', 'vote']);
    acl.allow(
      'admin',
      'member',
      'block',
      and(not(resourceHasRoles('admin', 'owner')), not(userIsResource)),
    );
    acl.allow('admin', 'member', 'delete', not(resourceHasRoles('admin', 'owner')));
    acl.allow(
      'admin',
      'member',
      'promote-moderator',
      and(not(resourceIsBlocked), not(resourceHasRoles('admin', 'owner')), not(userIsResource)),
    );

    acl.deny('admin', 'post', 'edit', not(userIsResourceOwner));
    acl.deny('admin', 'post', 'report');
    acl.deny('admin', 'comment', 'report');

    // Owner
    acl.allow('owner', 'forum', 'edit');
    acl.allow('owner', 'member', 'block', not(resourceHasRoles('owner')));
    acl.allow('owner', 'member', 'delete', not(resourceHasRoles('owner')));
    acl.allow(
      'owner',
      'member',
      'promote',
      and(not(resourceIsBlocked), not(resourceHasRoles('owner'))),
    );
    acl.deny('owner', 'member', 'report');

    return acl;
  };

  const acl = setupAcl(new AclService());
  const aclSee = setupAcl(new AclService(), hasMembersFunctions);

  const wrapped = {
    ROLE_GUEST,
    ROLE_USER,
    ROLE_MODERATOR,
    ROLE_ADMIN,
    ROLE_OWNER,
    RESOURCE_FORUM,
    RESOURCE_CATEGORY,
    RESOURCE_POST,
    RESOURCE_COMMENT,
    RESOURCE_MEMBER,
    PERMISSIONS_ALL,

    resourcify,
    setUserIdentity: user => {
      const identity = { ...user };
      let role = getUserRole(identity);

      if (isEmpty(identity)) {
        role = 'guest';
      }
      if (isEmpty(role)) {
        role = identity.isOwner ? 'owner' : 'user';
      }
      if (get(identity, 'isBlocked')) {
        role = 'guest';
      }
      // when member area not installed, we don't care about user being private.
      if (!isMemberAreaInstalled) {
        identity.privacyStatus = USER_PRIVACY_STATUS_PUBLIC;
      }
      acl.setUserIdentity(rolify(identity, role));
      aclSee.setUserIdentity(rolify(identity, role));
    },
    can: (resource, permission) => acl.can(resource, permission),
    canSee: (resource, permission) => aclSee.can(resource, permission),
  };
  wrapped.setUserIdentity(user);
  return wrapped;

  function not(fn) {
    return (...args) => !fn.apply(this, args);
  }

  function and(...fns) {
    return (...args) => every(fns, fn => fn.apply(this, args));
  }

  function or(...fns) {
    return (...args) => some(fns, fn => fn.apply(this, args));
  }

  function userHasPermission(permission) {
    return (user, resource) => {
      const permissions = get(user, `permissions.${permission}`, []);
      return includes(permissions, PERMISSIONS_ALL) || includes(permissions, resolve(resource)._id);
    };
  }

  function userIsResource(user, resource) {
    return user._id === resource._id;
  }

  function userIsResourceOwner(user, resource) {
    return user._id === resource.ownerId;
  }

  function userIsPrivate(user) {
    return user.privacyStatus === USER_PRIVACY_STATUS_PRIVATE;
  }

  function userIsBlocked(user) {
    return user.isBlocked;
  }

  function resourceHasCommentsDisabled(user, resource) {
    return resource.isCommentsDisabled;
  }

  function resourceHasRoles(...roles) {
    return (user, resource) => includes(roles, getUserRole(resource));
  }

  function resourceOwnerHasRoles(...roles) {
    return (user, resource) => includes(roles, getUserRole(get(resource, 'owner')));
  }

  function resourceIsMembersOnly(user, resource) {
    return resolve(resource).isPrivate || resolve(resource).type === 'membersOnly';
  }

  function resourceIsAccesible(user, resource) {
    if (resolve(resource).type === 'private') {
      const hasMatchingElements = (arr1, arr2) => arr1.some(r => arr2.includes(r));
      const groups = resolve(resource).groups || [];
      return hasMatchingElements(groups, user.groups || []);
    }
    return true;
  }

  function resourceIsWriteProtected(user, resource) {
    return resolve(resource).isWriteProtected;
  }

  function resourceIsBlocked(user, resource) {
    return resource.isBlocked;
  }

  function rolify(obj, role) {
    return { ...obj, getRoles: () => [role] };
  }
}

export function resourcify(obj, resource) {
  return obj ? { ...obj, getResourceId: () => resource } : resource;
}
