const MAX_DESCRIPTION_LENGTH = 160;

export const getMetaTags = ({ title = '', description = '', image, type }) => {
  const metaTags = [
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description ? description.slice(0, MAX_DESCRIPTION_LENGTH) : '',
    },
    {
      property: 'og:type',
      content: type,
    },
  ];

  if (image && image.src) {
    metaTags.push({
      property: 'og:image',
      content: image.src,
    });
  }
  if (image && image.width) {
    metaTags.push({
      property: 'og:image:width',
      content: image.width,
    });
  }
  if (image && image.height) {
    metaTags.push({
      property: 'og:image:height',
      content: image.height,
    });
  }

  return metaTags;
};

export const buildFullTitle = ({ forumTitle, title, page, t }) => {
  let result = title || '';
  if (page > 1) {
    if (result) {
      result += ' - ';
    }
    result += t('page-metadata.page', { page });
  }
  if (result) {
    result += ' | ';
  }
  result += forumTitle;
  return result;
};
