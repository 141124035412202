import { DELETE_CATEGORY } from '../../../constants/interactions';
import { CATEGORY_DELETE } from '../../messages/message-types';
import { deleteCategory } from '../../../actions/delete-category';
import { userEventsDeleteCategory } from '../../../actions/user-events';
import { navigateWithinForum } from '../../../../common/actions/navigate-within-forum';
import { getLocation } from '../../../../common/store/location/location-selectors';
import { getCategory } from '../../../selectors/categories-selectors';

export const deleteCategoryModalResolver = (categoryId, { showMessage }) => (
  dispatch,
  getState,
  { fedopsLogger },
) => {
  fedopsLogger.interactionStarted(DELETE_CATEGORY);
  dispatch(userEventsDeleteCategory({ categoryId, origin: 'liveSite' }));
  const state = getState();
  const location = getLocation(state);
  const category = getCategory(state, categoryId);
  const isInsideCategory = location.pathname.includes(category.slug);
  if (isInsideCategory) {
    dispatch(navigateWithinForum('/'));
  }
  return dispatch(deleteCategory(categoryId)).then(() => {
    fedopsLogger.interactionEnded(DELETE_CATEGORY);
    return showMessage(CATEGORY_DELETE);
  });
};
