import { getAllCategories } from '../selectors/categories-selectors.js';
import { updateCategoryRanks } from './update-category-ranks.js';
import { updateCategories } from './update-categories.js';

function promoteSubcategoriesToParent(parentId, categories) {
  return categories.map(category => {
    if (category.parentId === parentId) {
      return {
        ...category,
        parentId: null,
      };
    }
    return category;
  });
}

export const promoteSubcategories = parentId => (dispatch, getState) => {
  const categories = getAllCategories(getState());
  const nextCategories = promoteSubcategoriesToParent(parentId, categories);

  return dispatch(updateCategoryRanks(nextCategories)).then(() => {
    dispatch(updateCategories(nextCategories));
  });
};
