import { findKey, filter } from 'lodash';
import * as LAYOUT_TYPES from '../constants/layout-types';
import { getLocation, getPrevPath } from '../../common/store/location/location-selectors';
import detectRoute from '../services/detect-route';
import { QUESTION, DISCUSSION } from '../constants/post-types';
import { voteType } from '../actions/vote-comment';

const VOTE = 'vote';
const DOWNVOTE = 'downvote';
const UNVOTE = 'unvote';
const UNDOWNVOTE = 'undownvote';

export const POST_LIMITER_VALUES = {
  'forum-limit-1-new-post': 1,
  'forum-limit-5-new-post': 5,
  'forum-limit-10-new-post': 10,
};

const DUMMY_VISITOR_ID = '00000000-0000-0000-0000-000000000000';

export function getReferrer(state) {
  return detectRoute(getPrevPath(state));
}

export function getCurrentOrigin(state) {
  return detectRoute(getLocation(state));
}

export function getLayoutName(id) {
  id = parseInt(id, 10);
  return findKey(LAYOUT_TYPES, type => type === id).toLowerCase();
}

export function getImageCount(entity) {
  const content = entity.content;
  return filter(content.entityMap, entity => entity.type === 'wix-draft-plugin-image').length;
}

export function getFileCount(entity) {
  const content = entity.content;
  return filter(content.entityMap, entity => entity.type === 'wix-draft-plugin-file-upload').length;
}

export function getHashtagCount(entity) {
  const hashtags = entity.hashtags || [];
  return hashtags.length;
}

export function getEmojiCount(entity) {
  const content = entity.content;
  return filter(content.entityMap, entity => entity.type === 'emoji').length;
}

export function getMentionCount(entity) {
  const content = entity.content;
  return filter(content.entityMap, entity => entity.type === 'mention').length;
}

export function getQuoteCount(entity) {
  const content = entity.content;
  return filter(content.blocks, entity => entity.type === 'blockquote').length;
}

export function getVideoCount(entity) {
  const content = entity.content;
  return filter(
    content.entityMap,
    entity => entity.type === 'wix-draft-plugin-video' || entity.type === 'VIDEO-EMBED',
  ).length;
}

export function hasBiToken(event) {
  return !!event.biToken;
}

export function isDummyVisitorId(visitorId) {
  return visitorId === DUMMY_VISITOR_ID;
}

export function hasMemberId(event) {
  return !!event.member_id;
}

export function serializeToggleState(action) {
  return action.payload.isEnabled ? 'on' : 'off';
}

export function getCategoryPostType(category) {
  const ALL = 'all';
  let format;
  if (category.postTypes.length) {
    format =
      category.postTypes.length > 1
        ? ALL
        : category.postTypes.includes(QUESTION)
        ? QUESTION
        : DISCUSSION;
  } else {
    format = ALL;
  }
  return format;
}

export function getVoteType(payload, comment) {
  switch (payload.voteType) {
    case voteType.UP:
      return VOTE;
    case voteType.DOWN:
      return DOWNVOTE;
    case voteType.UNVOTE:
      return comment.isUpvoted ? UNVOTE : UNDOWNVOTE;
    default:
      return;
  }
}

export function getVoteFinalScore(voteType, comment) {
  let { upvoteCount, downvoteCount } = comment;
  const hasVoteCurrently = comment.isDownvoted || comment.isUpvoted;

  switch (voteType) {
    case VOTE:
      if (hasVoteCurrently) {
        downvoteCount--;
      }
      upvoteCount++;
      break;
    case UNVOTE:
      upvoteCount--;
      break;
    case DOWNVOTE:
      if (hasVoteCurrently) {
        upvoteCount--;
      }
      downvoteCount++;
      break;
    case UNDOWNVOTE:
      downvoteCount--;
      break;
    default:
      return;
  }
  return upvoteCount - downvoteCount;
}
