import isObject from 'is-plain-object';

export function subscribeToChange(store, onChange) {
  let currentState = store.getState();
  store.subscribe(() => {
    // const prevState = currentState;
    currentState = store.getState();
    return onChange(currentState);
    //   const diff = [];
    //
    //   if (prevState === currentState) {
    //     return;
    //   }
    //
    //   Object.keys(currentState).forEach(key => {
    //     if (prevState[key] !== currentState[key]) {
    //       findDiff(prevState, currentState, [key], diff);
    //     }
    //   });
    //
    //   if (diff.length > 0) {
    //     onChange(diff);
    //   }
  });
}

export function findDiff(prevState, currentState, path, diffs = []) {
  const lastPath = path[path.length - 1];
  const val = currentState[lastPath];

  if (isObject(val)) {
    const valPrev = prevState[lastPath];

    // handle a new top level reducer case
    if (!valPrev) {
      diffs.push([path, val]);
      // check if both have same keys
    } else if (hasDifferentKeys(val, valPrev)) {
      diffs.push([path, val]);
    } else {
      Object.keys(val).forEach(nextPath => {
        if (val[nextPath] !== valPrev[nextPath]) {
          findDiff(valPrev, val, path.concat(nextPath), diffs);
        }
      });
    }
  } else {
    diffs.push([path, val]);
  }

  return diffs;
}

function hasDifferentKeys(o1, o2) {
  const k1 = Object.keys(o1);
  const k2 = Object.keys(o2);

  return k1.length !== k2.length || k1.some(k => k2.indexOf(k) === -1);
}
