import { noop } from 'lodash';
import { PROVISION_SUCCESS } from '../../app/constants/interactions';
import createRequest from './create-request';
import Wix from '../../app/services/wix-sdk-polyfill';

/**
 * Handles internal provisioning
 *
 * Forum internal provisioning happens on server by listening to kafka event of forum provision.
 * Once that happens - forum is initializing data (categories, posts, etc).
 *
 * Provisioning handling done in such manner:
 *
 * 1. API `/provision/status` is called to check provision status
 *    HTTP Status codes:
 *    200 - provisioning done
 *    404 - provisioning not started yet
 *    409 - provisioning in progress
 *    403 - Forbidden
 *    500 - Server error
 *
 * 2. If status 200 - call regular page ready to display forum
 *
 * 3. If status 404 or 409 -
 *    show loader ->
 *    poll provision status endpoint for success (retries multiple times with exponential backoff) ->
 *    call page ready ->
 *    hide loader
 *
 * 4. If status 403/500 - show error page
 */
export function handleProvisioning(appParams, fedopsLogger, wixCodeApi, setProps, pageReady) {
  const isSSR = wixCodeApi.window.rendering.env === 'backend';

  const request = createRequest({
    baseUrl: appParams.baseUrls.apiBaseUrl,
    getInstance: () => wixCodeApi.user.currentUser.instance,
    locale: wixCodeApi.site.language,
    trackError: console.error,
  });

  const provisionIfNeeded = () => {
    if (process.env.NODE_ENV === 'production') {
      return Promise.resolve();
    }

    return start().catch(response => {
      if (response.status === 404) {
        const siteTextPresets = Wix.Styles.getSiteTextPresets();
        const siteColors = Wix.Styles.getSiteColors();

        return request
          .post('/provision/memberServiceProvision', {
            siteTextPresets,
            siteColors,
          })
          .catch(noop);
      }
    });
  };
  const start = () => request('/provision/status');
  const sleep = timeout => new Promise(resolve => setTimeout(resolve, timeout));
  const retry = (fn, retries = 10, timeout = 300) =>
    new Promise((resolve, reject) => {
      const doRetry = retries =>
        sleep(timeout)
          .then(() => retry(fn, retries - 1, timeout * 2))
          .then(resolve, reject);

      fn().then(
        response => {
          if (response === undefined) {
            resolve();
          } else if (response.status === 403 && retries > 1) {
            return doRetry(retries > 3 ? 3 : retries); // if forbidden do less
          } else if (response.status === 409 && retries > 1) {
            return doRetry(retries);
          } else if (response.status >= 500 && retries > 1) {
            return doRetry(retries);
          }
          resolve(response);
        },
        error => {
          if (retries > 1) {
            return doRetry(retries);
          }
          reject(error);
        },
      );
    });

  return provisionIfNeeded()
    .then(() => start())
    .then(() => pageReady())
    .catch(response => {
      if (response.status === 404 || response.status === 409) {
        fedopsLogger.interactionStarted(PROVISION_SUCCESS);
        setProps({ provisioningInProgress: true });
        retry(start)
          .then(() => {
            pageReady().then(() => {
              fedopsLogger.interactionEnded(PROVISION_SUCCESS);
              setProps({ provisioningInProgress: false });
            });
          })
          .catch(() => {
            setProps({
              provisioningError: true,
              provisioningInProgress: false,
              provisioningTimeout: true,
            });
          });
        return Promise.resolve();
      }
    });
}
