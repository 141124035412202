export const SORT_BY_BEST_MATCH = 'sortByBestMach';
export const SORT_BY_LAST_ACTIVITY = 'sortByLastActivity';
export const SORT_BY_NEWEST = 'sortByNewest';
export const SORT_BY_COMMENTS = 'sortByMostComments';
export const SORT_BY_MOST_VIEWS = 'sortByMostViews';
export const SORT_BY_LIKES = 'sortByLikes';

export const mapToAppSettings = {
  [SORT_BY_LAST_ACTIVITY]: '1',
  [SORT_BY_NEWEST]: '2',
  [SORT_BY_COMMENTS]: '3',
  [SORT_BY_MOST_VIEWS]: '4',
  [SORT_BY_LIKES]: '5',
};

const param = {
  BEST_MATCH: '',
  CREATED_DATE: 'createdDate',
  LAST_ACTIVITY_DATE: 'lastActivityDate',
  TOTAL_COMMENTS: 'totalComments',
  VIEW_COUNT: 'viewCount',
  LIKE_COUNT: 'likeCount',

  ORDER_ASC: 'asc',
  ORDER_DESC: 'desc',
};

const mapSortToQueryParams = {
  [SORT_BY_BEST_MATCH]: {
    sort: param.BEST_MATCH,
  },
  [SORT_BY_NEWEST]: {
    sort: param.CREATED_DATE,
    order: param.ORDER_DESC,
  },
  [SORT_BY_LAST_ACTIVITY]: {
    sort: param.LAST_ACTIVITY_DATE,
    order: param.ORDER_DESC,
  },
  [SORT_BY_COMMENTS]: {
    sort: param.TOTAL_COMMENTS,
  },
  [SORT_BY_MOST_VIEWS]: {
    sort: param.VIEW_COUNT,
  },
  [SORT_BY_LIKES]: {
    sort: param.LIKE_COUNT,
  },
};

export const getSortQueryParams = sort => mapSortToQueryParams[sort];
