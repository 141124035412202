import { isEqual } from 'lodash';
import { TEMPLATE_CATEGORY_ID } from '../constants/categories-manager';
import { getCategory } from './categories-selectors';
import { getForumData } from './forum-data-selectors';

export const getState = state => state.categoriesManager;
export const getCategoryInEditId = state => getState(state).categoryInEditId;
export const getInitialCopy = state => getState(state).categoryCopy;
export const isNewCategory = state => getCategoryInEditId(state) === TEMPLATE_CATEGORY_ID;
export const isCategoryEdited = state => {
  const id = getCategoryInEditId(state);
  const original = getInitialCopy(state);
  const current = getCategory(state, id);

  return current && !isEqual(original, current);
};
export const isForumDataEdited = state => {
  const original = getInitialCopy(state);
  const current = getForumData(state);

  return current && !isEqual(original, current);
};
