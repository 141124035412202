import { get } from 'lodash';
import { getCategory } from '../selectors/categories-selectors';
import { getCommunityId } from '../selectors/forum-data-selectors';
import { getApplicationConfig } from '../selectors/communities-context-selectors';
import { permissionsChecker } from '../components/permissions-context/permissions-checker';
import { getCurrentUser } from '../store/current-user/current-user-selectors';
import {
  MEMBERS_APP_ID,
  SANTA_MEMBERS_APP_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';

/*
Which permissions checker should I use?

* can - use when you only care about if user can do action or not - using this carries a message
  that action is visible only when user can actually do it.
* canSee - use it when you want to show that user could do an action, even
  if he currently can't. For example - like a post as logged in, but private member.
  Using this should be accompanied by decorating for user to login or become a public member.
* canRender - use it everywhere where you render UI based on permissions. It checks
  both can and canSee.*/

const createPermissionsChecker = (permissions, isSee) => (permission, resource, obj) => {
  return isSee
    ? permissions.canSee(permissions.resourcify(obj, resource), permission)
    : permissions.can(permissions.resourcify(obj, resource), permission);
};

const createPermissionsRenderHelper = (canSeeChecker, canChecker) => (
  permission,
  resource,
  obj,
  render,
) => {
  const renderFunc = render ? render : obj;
  const can = canChecker(permission, resource, obj);
  const canSee = canSeeChecker(permission, resource, obj);
  return can || canSee ? renderFunc(can, canSee) : null;
};

export const createHelpers = permissions => {
  const canSeePermissionsHelper = createPermissionsChecker(permissions, true);
  const canPermissionsHelper = createPermissionsChecker(permissions, false);
  const permissionsRenderHelper = createPermissionsRenderHelper(
    canSeePermissionsHelper,
    canPermissionsHelper,
  );

  return {
    permissions,
    can: canPermissionsHelper,
    canSee: canSeePermissionsHelper,
    canRender: permissionsRenderHelper,
  };
};

export const getPermissions = state => {
  const isOldForum = Boolean(getCommunityId(state));
  const isMemberAppInstalled = get(
    getApplicationConfig(state, MEMBERS_APP_ID),
    'isInstalled',
    false,
  );
  const isMemberAreaInstalled = get(
    getApplicationConfig(state, SANTA_MEMBERS_APP_ID),
    'isInstalled',
    false,
  );
  const flags = { isOldForum, isMemberAppInstalled, isMemberAreaInstalled };

  return permissionsChecker(flags, getCurrentUser(state), resource =>
    resourceResolver(state)(resource),
  );
};

const resourceResolver = state => resource => {
  if (resource.categoryId) {
    resource = getCategory(state, resource.categoryId);
  }
  return resource;
};
