// import { parseAppSettings } from '../services/parse-app-settings';

export const SET_APP_SETTINGS = 'appSettings/SET_APP_SETTINGS';

export const setAppSettings = payload => dispatch => {
  dispatch({ type: SET_APP_SETTINGS, payload });
  return Promise.resolve();
};

export const FETCH_APP_SETTINGS_REQUEST = 'appSettings/FETCH_REQUEST';
export const FETCH_APP_SETTINGS_SUCCESS = 'appSettings/FETCH_SUCCESS';
export const FETCH_APP_SETTINGS_FAILURE = 'appSettings/FETCH_FAILURE';

export const fetchAppSettingsRequest = () => ({
  type: FETCH_APP_SETTINGS_REQUEST,
});
export const fetchAppSettingsSuccess = payload => ({
  type: FETCH_APP_SETTINGS_SUCCESS,
  payload,
});
export const fetchAppSettingsFailure = () => ({
  type: FETCH_APP_SETTINGS_FAILURE,
});

// export function fetchAppSettings(params) {
//   return (dispatch, getState, { request }) => {
//     dispatch(fetchAppSettingsRequest());
//     const { compId, viewMode, externalId } = parseAppSettings(params, getState());
//     const promise = request(
//       `/settings?compId=${compId}&externalId=${externalId}&viewMode=${viewMode}`,
//     );

//     return promise
//       .then(settings => dispatch(fetchAppSettingsSuccess(settings)))
//       .catch(() => dispatch(fetchAppSettingsFailure()))
//       .then(() => promise);
//   };
// }

export function fetchPageAppSettings(viewMode) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchAppSettingsRequest());

    viewMode = viewMode === '' ? 'site' : viewMode;

    const promise = request(`/settings/page?viewMode=${viewMode}`);
    return promise
      .then(
        settings => dispatch(fetchAppSettingsSuccess(settings)),
        () => dispatch(fetchAppSettingsFailure()),
      )
      .then(() => promise);
  };
}

export const UPDATE_APP_SETTINGS_REQUEST = 'appSettings/UPDATE_REQUEST';
export const UPDATE_APP_SETTINGS_SUCCESS = 'appSettings/UPDATE_SUCCESS';
export const UPDATE_APP_SETTINGS_FAILURE = 'appSettings/UPDATE_FAILURE';
export const UPDATE_APP_SETTINGS_ON_STORE = 'appSettings/UPDATE_ON_STORE';

export const updateAppSettingsRequest = (payload, externalId) => ({
  type: UPDATE_APP_SETTINGS_REQUEST,
  payload,
  meta: {
    bi: {
      externalId,
    },
  },
});
export const updateAppSettingsSuccess = payload => ({
  type: UPDATE_APP_SETTINGS_SUCCESS,
  payload,
});
export const updateAppSettingsFailure = () => ({
  type: UPDATE_APP_SETTINGS_FAILURE,
});

// eslint-disable-next-line max-params
export const updateAppSettings = (compId, externalId, viewMode, settings, isMainPage = false) => (
  dispatch,
  getState,
  { request },
) => {
  dispatch(updateAppSettingsRequest(settings, externalId));

  return request
    .post(
      `/settings?compId=${compId}&externalId=${externalId}&viewMode=${viewMode}&isMainPage=${isMainPage}`,
      settings,
    )
    .then(responseData => dispatch(updateAppSettingsSuccess(responseData)))
    .catch(() => dispatch(updateAppSettingsFailure()));
};

// only perform update localy - do not change the settings on server - this is used in wix change style in the editor (before save/publish)
export const updateAppSettingsOnStore = payload => ({
  type: UPDATE_APP_SETTINGS_ON_STORE,
  payload,
});

export const updateNumericValueOnStore = (key, value) =>
  updateAppSettingsOnStore({
    style: {
      numbers: {
        [key]: parseInt(value, 10),
      },
    },
  });

export const updateBooleanValueOnStore = (key, value) =>
  updateAppSettingsOnStore({
    style: {
      booleans: {
        [key]: value,
      },
    },
  });

export const updateCustomAppSettingsValue = (key, value) =>
  updateAppSettingsOnStore({
    custom: {
      [key]: value,
    },
  });
