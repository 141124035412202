import { get } from 'lodash';
import getImageUrl from './get-image-url';

const FB_MIN_THUMBNAIL_SIZE = 200;

export const getImageMetadataFromWixMedia = image => ({
  src: get(image, 'file_name') ? getImageUrl(image) : null,
  height: get(image, 'height', null),
  width: get(image, 'width', null),
});

export const getVideoThumbnailMetadata = videoMetadata => {
  return isVimeo(videoMetadata)
    ? getVimeoThumbnailMetadata(videoMetadata)
    : {
        src: get(videoMetadata, 'thumbnail_url', null),
        height: get(videoMetadata, 'height', null),
        width: get(videoMetadata, 'width', null),
      };
};

const isVimeo = metadata => get(metadata, 'provider_name', '').toLowerCase() === 'vimeo';

const getVimeoThumbnailMetadata = metadata => {
  const { thumbnail_width: width, thumbnail_height: height } = metadata;
  const adjustedWidth = Math.max(width, FB_MIN_THUMBNAIL_SIZE);
  const adjustedHeight = Math.max(height, FB_MIN_THUMBNAIL_SIZE);
  const re = new RegExp(`${width}x${height}`);
  const src = get(metadata, 'thumbnail_url', '').replace(re, `${adjustedWidth}x${adjustedHeight}`);
  return { src, height: adjustedHeight, width: adjustedWidth };
};
