import {
  SANTA_MEMBERS_APP_ID,
  MEMBER_INFO_APP_ID,
  MEMBER_INFO_SECTION_ID,
  PAID_PLANS_APP_ID,
  PAID_PLANS_SECTION_ID,
} from '@wix/communities-universal/dist/src/constants/appsConfig';

export const SET_COMMUNITIES_CONTEXT = 'communitiesContext/SET';

export const isMaInstalled = wixCodeApi =>
  Promise.resolve(
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: MEMBER_INFO_APP_ID,
      sectionId: MEMBER_INFO_SECTION_ID,
    }),
  );

export const isPaidPlansInstalled = wixCodeApi =>
  Promise.resolve(
    wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: PAID_PLANS_APP_ID,
      sectionId: PAID_PLANS_SECTION_ID,
    }),
  );

export const setCommunitiesContext = () => (dispatch, getState, { wixCodeApi }) => {
  Promise.all([isMaInstalled(wixCodeApi), isPaidPlansInstalled(wixCodeApi)]).then(
    ([isInstalledA, isInstalledB]) => {
      dispatch({
        type: SET_COMMUNITIES_CONTEXT,
        payload: {
          [SANTA_MEMBERS_APP_ID]: {
            isInstalled: isInstalledA,
          },
          [PAID_PLANS_APP_ID]: {
            isInstalled: isInstalledB,
          },
        },
      });
    },
  );
};
