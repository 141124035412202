export const INCREMENT_COMMENT_LIKE_COUNT_REQUEST = 'comment/INCREMENT_LIKE_COUNT_REQUEST';
export const INCREMENT_COMMENT_LIKE_COUNT_SUCCESS = 'comment/INCREMENT_LIKE_COUNT_SUCCESS';
export const INCREMENT_COMMENT_LIKE_COUNT_FAILURE = 'comment/INCREMENT_LIKE_COUNT_FAILURE';

export const incrementCommentLikeCountRequest = ({ commentId, postId }) => ({
  type: INCREMENT_COMMENT_LIKE_COUNT_REQUEST,
  payload: { _id: commentId, postId },
});
export const incrementCommentLikeCountFailure = ({ commentId, postId }) => ({
  type: INCREMENT_COMMENT_LIKE_COUNT_FAILURE,
  payload: { _id: commentId, postId },
});
export const incrementCommentLikeCountSuccess = ({ commentId, postId, count, isLiked }) => ({
  type: INCREMENT_COMMENT_LIKE_COUNT_SUCCESS,
  payload: { _id: commentId, postId, likeCount: count, isLiked },
});

export function incrementCommentLikeCount({ commentId, postId }) {
  return (dispatch, getState, { request }) => {
    dispatch(incrementCommentLikeCountRequest({ commentId, postId }));

    const promise = request.post(`/posts/${postId}/comments/${commentId}/likes`);
    return promise
      .then(data => dispatch(incrementCommentLikeCountSuccess({ commentId, postId, ...data })))
      .catch(() => dispatch(incrementCommentLikeCountFailure({ commentId, postId })))
      .then(() => promise);
  };
}
