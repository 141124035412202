import _ from 'lodash';
import Wix from './wix-sdk-polyfill';
import { updateAppSettings } from '../../common/store/app-settings/app-settings-actions';
import { setComponentData } from '../../common/store/component-data/component-data-actions';
import { setStyleParams } from '../../common/store/style-params/style-params-actions';
import { getStyleParams } from '../../common/store/style-params/style-params-selectors';
import { getIsForumStructureRegular } from '../selectors/app-settings-selectors';

const EXCLUDED_CSS_FONTS = /fonts\.googleapis/;

export default function listenToSettingsChange(store, isMainPage = false, doRouting) {
  Wix.addEventListener(Wix.Events.SITE_SAVED, () => {
    Wix.Styles.getStyleParams(style => {
      store.dispatch(
        updateAppSettings(
          Wix.Utils.getCompId(),
          'draft',
          'editor',
          getStyleSettings(style),
          isMainPage,
        ),
      );
    });
  });

  Wix.addEventListener(Wix.Events.SITE_PUBLISHED, () => {
    Wix.Styles.getStyleParams(style => {
      Wix.getExternalId(externalId => {
        store.dispatch(
          updateAppSettings(
            Wix.Utils.getCompId(),
            externalId || 'published',
            'editor',
            getStyleSettings(style),
            isMainPage,
          ),
        );
      });
    });
  });

  Wix.addEventListener(Wix.Events.PUBLIC_DATA_CHANGED, data =>
    store.dispatch(setComponentData(data)),
  );
  Wix.addEventListener(Wix.Events.SETTINGS_UPDATED, data => store.dispatch(data));

  Wix.addEventListener(Wix.Events.STYLE_PARAMS_CHANGE, styleParams => {
    const state = store.getState();
    const forumStructureRegularPrev = getIsForumStructureRegular({
      styleParams: getStyleParams(state),
    });
    const forumStructureRegularNext = getIsForumStructureRegular({ styleParams });

    if (forumStructureRegularPrev !== forumStructureRegularNext) {
      setTimeout(() => doRouting());
    }
    store.dispatch(setStyleParams(styleParams));
  });
}

function getStyleSettings(style = {}) {
  return {
    style: {
      ...style,
      cssUrls: getFontCssUrls(),
    },
    textPresets: Wix.Styles.getSiteTextPresets() || [],
    colorPresets: Wix.Styles.getSiteColors() || [],
  };
}

function getFontCssUrls() {
  return _(document.styleSheets)
    .filter(styleSheet => styleSheet.href)
    .map(styleSheet => styleSheet.href.toLowerCase())
    .map(href => href.replace(/^https?:/, ''))
    .filter(href => !EXCLUDED_CSS_FONTS.test(href))
    .filter(href => href.indexOf('font') >= 0)
    .uniq()
    .value();
}
