export const createSiteMembersFetchService = ({ instance, apiBaseUrl }) => query =>
  fetch(`${apiBaseUrl}mentions?query=${query}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      instance,
    },
  })
    .then(response => {
      const status = response.status;

      if (status < 400) {
        return response.json().catch(() => Promise.resolve());
      }

      return Promise.reject(response);
    })
    .then(profiles =>
      profiles.map(p => ({ name: p.nickname, slug: p.slug, avatar: p.imageUrl, id: p.id })),
    );

export const getMentionsFromEditorState = editorState => {
  if (!editorState || !editorState.entityMap) {
    return [];
  }

  return Object.values(editorState.entityMap)
    .filter(entity => entity.type === 'mention')
    .map(entity => entity.data.mention);
};

export const extractMentionsFromContent = content =>
  getMentionsFromEditorState(content).map(item => item.id);
