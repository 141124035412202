import { flatMap, get } from 'lodash';
import immer from 'immer';
import { FETCH_POSTS_SUCCESS } from '../actions/fetch-posts';
import { FETCH_COMMENTS_SUCCESS } from '../actions/fetch-comments-constants';
import { REPLIES_PER_PAGE, getCommentsPerPage } from '../constants/pagination';
import { FETCH_COMMENT_SUCCESS } from '../actions/fetch-comment';
import { CREATE_COMMENT_SUCCESS } from '../actions/create-comment';
import { DELETE_COMMENT_SUCCESS } from '../actions/delete-comment';
import { DELETE_POST_SUCCESS } from '../actions/delete-post';
import { MOVE_POST_SUCCESS } from '../actions/move-post';
import { getLastPage } from '../services/pagination';
import { FETCH_POST_PAGE_DATA_SUCCESS } from '../actions/fetch-post-page-data';
import { RESET_PAGINATION } from '../actions/reset-pagination';

export const UNCATEGORIZED_POSTS = 'UNCATEGORIZED';

const initialState = {
  posts: {
    [UNCATEGORIZED_POSTS]: {
      entitiesByPage: {},
      entityCount: 0,
      sort: null,
    },
  },
  comments: {},
};

const getNewEntities = (entities, meta) => ({
  entitiesByPage: {
    [meta.page]: entities.map(entity => entity._id),
  },
  entityCount: meta.entityCount,
});

// posts pagination data is saved by category id. it's used for pagination and ordering.
// when sort value changes we reset the entitiesByPage, so that sorting is correct.
const createPostsEntities = (entities, meta, currentState) => {
  const categoryId = meta.categoryId || UNCATEGORIZED_POSTS;
  const shouldResetEntitiesState = get(currentState, `${categoryId}.sort`) !== meta.sort;
  return {
    ...currentState,
    [categoryId]: {
      entitiesByPage: {
        ...(shouldResetEntitiesState ? {} : currentState[categoryId].entitiesByPage),
        [meta.page]: entities.map(entity => entity._id),
      },
      entityCount: meta.entityCount,
      sort: meta.sort,
    },
  };
};

const appendComment = (state = { entityCount: 0, entitiesByPage: {} }, comment, isMobile) => {
  if (flatMap(Object.values(state.entitiesByPage)).includes(comment._id)) {
    // this is to prevent sockets event duplication
    return state;
  }

  const newEntityCount = state.entityCount + 1;
  const lastPage = getLastPage(
    newEntityCount,
    comment.parentId ? REPLIES_PER_PAGE : getCommentsPerPage(isMobile),
  );
  const tempEntities = state.entitiesByPage[lastPage] || [];
  return {
    entitiesByPage: {
      ...state.entitiesByPage,
      [lastPage]: [...tempEntities, comment._id],
    },
    entityCount: newEntityCount,
  };
};

// we simply find a deleted comment id in one of the pages and remove it form that list.
function deleteComment(comments, commentId) {
  return immer(comments, newComments => {
    Object.entries(newComments).some(([id, { entityCount, entitiesByPage }]) => {
      return Object.entries(entitiesByPage).some(([page, entities]) => {
        if (entities.includes(commentId)) {
          newComments[id].entityCount = entityCount - 1;
          newComments[id].entitiesByPage[page] = newComments[id].entitiesByPage[page].filter(
            cId => cId !== commentId,
          );

          return true;
        }
      });
    });
  });
}

const removePost = (posts, deletedPostId) =>
  Object.entries(posts).reduce((result, [key, value]) => {
    let containsDeleted = false;
    result[key] = {
      ...value,
      entitiesByPage: Object.entries(value.entitiesByPage).reduce(
        (entitiesResult, [page, postsIds]) => {
          containsDeleted = postsIds.includes(deletedPostId);
          entitiesResult[page] = postsIds.filter(id => id !== deletedPostId);
          return entitiesResult;
        },
        {},
      ),
    };

    if (containsDeleted) {
      result[key].entityCount -= 1;
    }
    return result;
  }, {});

// when user navigates between categories, we reset pagination state, so that only 1st page is left.
// this is because we always refetch 1st page (but not the rest), so user will see latest data.
const resetPostsPagination = postsState =>
  Object.entries(postsState).reduce((result, [key, value]) => {
    result[key] = {
      entitiesByPage: {},
      entityCount: value.entityCount,
    };

    if (value.entitiesByPage[1]) {
      result[key].entitiesByPage[1] = value.entitiesByPage[1];
    }

    return result;
  }, {});

export default (state = initialState, { type, payload, meta = {} } = {}) => {
  switch (type) {
    case RESET_PAGINATION:
      return {
        ...state,
        posts: resetPostsPagination(state.posts),
      };
    case FETCH_POSTS_SUCCESS:
      return {
        ...state,
        posts: createPostsEntities(payload, meta, state.posts),
      };
    case MOVE_POST_SUCCESS:
    case DELETE_POST_SUCCESS:
      return {
        ...state,
        posts: removePost(state.posts, payload),
      };
    case FETCH_COMMENTS_SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          [meta.paginationId]: getNewEntities(payload.comments, meta),
        },
      };
    case FETCH_POST_PAGE_DATA_SUCCESS: {
      let nextState;

      nextState = {
        ...state,
        comments: {
          ...state.comments,
          [payload.result.post._id]: getNewEntities(payload.result.comments.result, {
            page: payload.page,
            paginationId: payload.result.post._id,
            entityCount: parseInt(payload.result.comments.totalResultCount, 10),
          }),
        },
      };

      for (const { commentId, replies, totalResultCount } of payload.result.replies.result) {
        nextState = {
          ...nextState,
          comments: {
            ...nextState.comments,
            [commentId]: getNewEntities(replies, {
              page: payload.page,
              paginationId: commentId,
              entityCount: parseInt(totalResultCount, 10),
            }),
          },
        };
      }

      return nextState;
    }

    case CREATE_COMMENT_SUCCESS: {
      const id = meta.paginationId || payload.parentId || payload.postId;
      return {
        ...state,
        comments: {
          ...state.comments,
          [id]: appendComment(state.comments[id], payload, meta.isMobile),
        },
      };
    }
    case FETCH_COMMENT_SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          [payload.postId]: appendComment(state.comments[payload.postId], payload.comment),
        },
      };
    case DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        comments: {
          ...state.comments,
          ...deleteComment(state.comments, payload._id),
        },
      };
    default:
      return state;
  }
};
