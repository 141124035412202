// using same in forum and widgets to reuse code
export const IS_VIEW_COUNT_ENABLED = 'forum-isViewCountEnabled';
export const IS_VIEW_COUNT_ENABLED_PATH = `styleParams.booleans.${IS_VIEW_COUNT_ENABLED}`;

export const CARD_BACKGROUND_COLOR = 'post-cardBackgroundColor';
export const CARD_BACKGROUND_COLOR_PATH = `styleParams.colors.${CARD_BACKGROUND_COLOR}`;

export const POST_TITLE_FONT = 'post-titleFont';

export const POST_TITLE_COLOR = 'post-titleColor';
export const POST_TITLE_COLOR_PATH = `styleParams.colors.${POST_TITLE_COLOR}`;

export const POST_HOVER_COLOR = 'post-hoverColor';
export const POST_HOVER_COLOR_PATH = `styleParams.colors.${POST_HOVER_COLOR}`;

export const CARD_BORDER_COLOR = 'post-cardBorderColor';
export const CARD_BORDER_COLOR_PATH = `styleParams.colors.${CARD_BORDER_COLOR}`;

export const CARD_BORDER_WIDTH = 'post-cardBorderWidth';
export const CARD_BORDER_WIDTH_PATH = `styleParams.numbers.${CARD_BORDER_WIDTH}`;

export const WIDGET_BACKGROUND_COLOR = 'widget-backgroundColor';
export const WIDGET_BACKGROUND_COLOR_PATH = `styleParams.colors.${WIDGET_BACKGROUND_COLOR}`;

export const WIDGET_CATEGORY_ID = 'widget-categoryId';
export const WIDGET_POSTS_ORDER_BY = 'widget-postsOrderBy';

// List settings
export const LIST_LAYOUT_ID = 'list-layoutId';
export const LIST_LAYOUT_ID_PATH = `styleParams.numbers.${LIST_LAYOUT_ID}`;

export const IS_LIST_POST_NUMBERS_ENABLED = 'list-isPostNumbersEnabled';
export const IS_LIST_POST_NUMBERS_ENABLED_PATH = `styleParams.booleans.${IS_LIST_POST_NUMBERS_ENABLED}`;

export const LIST_NUMBER_OF_POSTS = 'list-numberOfPosts';
export const LIST_NUMBER_OF_POSTS_PATH = `styleParams.numbers.${LIST_NUMBER_OF_POSTS}`;
