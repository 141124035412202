import uri from 'urijs';
import createAction from '../../common/services/create-action';
import { buildPaginationRequestParams } from '../services/pagination';

export const FETCH_RECENT_POSTS_REQUEST = 'posts/FETCH_RECENT_REQUEST';
export const FETCH_RECENT_POSTS_SUCCESS = 'posts/FETCH_RECENT_SUCCESS';
export const FETCH_RECENT_POSTS_FAILURE = 'posts/FETCH_RECENT_FAILURE';

export const fetchRecentPostsRequest = createAction(FETCH_RECENT_POSTS_REQUEST);
export const fetchRecentPostsSuccess = createAction(FETCH_RECENT_POSTS_SUCCESS);
export const fetchRecentPostsFailure = createAction(FETCH_RECENT_POSTS_FAILURE);

export default function fetchRecentPosts({ categoryId, page = 1, pageSize, orderBy }) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchRecentPostsRequest(undefined, { categoryId }));

    const pageParams = buildPaginationRequestParams(page, pageSize);
    const params = {
      ...pageParams,
      ...(categoryId ? { categoryId } : {}),
    };

    const promise = request(uri(`/posts/feed/${orderBy}`).query({ ...params }), {
      parseHeaders: true,
    });

    return promise.then(
      ({ body = [] }) => dispatch(fetchRecentPostsSuccess(body)),
      () => dispatch(fetchRecentPostsFailure()),
    );
  };
}
