import createAction from '../../common/services/create-action';

export const FETCH_PAID_PLANS_REQUEST = 'paid-plans/FETCH_REQUEST';
export const FETCH_PAID_PLANS_SUCCESS = 'paid-plans/FETCH_SUCCESS';
export const FETCH_PAID_PLANS_FAILURE = 'paid-plans/FETCH_FAILURE';

export const fetchPaidPlansRequest = createAction(FETCH_PAID_PLANS_REQUEST);
export const fetchPaidPlansSuccess = createAction(FETCH_PAID_PLANS_SUCCESS, payload => payload);
export const fetchPaidPlansFailure = createAction(FETCH_PAID_PLANS_FAILURE);

export const fetchPaidPlans = () => {
  return (dispatch, getState, { paidPlansRequest, wixCodeApi }) => {
    dispatch(fetchPaidPlansRequest());
    const config = {
      headers: {
        Authorization: wixCodeApi.user.currentUser.instance,
        instance: null,
      },
    };
    const promise = paidPlansRequest('/v1/plans/public', config);

    return promise
      .then(
        ({ plans }) => dispatch(fetchPaidPlansSuccess(plans)),
        () => dispatch(fetchPaidPlansFailure()),
      )
      .then(() => promise);
  };
};
