import createAction from '../../common/services/create-action';

export const PICK_FORUM_SUBJECT = 'userEvents/PICK_FORUM_SUBJECT';
export const CHOOSE_FORUM_STRUCTURE = 'userEvents/CHOOSE_FORUM_STRUCTURE';
export const CUSTOMIZE_RCE = 'userEvents/CUSTOMIZE_RCE';
export const SHOW_FOOTER_POSTS = 'userEvents/SHOW_FOOTER_POSTS';
export const SHOW_HEADER = 'userEvents/SHOW_HEADER';
export const RESIZE_HEADER = 'userEvents/RESIZE_HEADER';
export const CUSTOMIZE_FEED = 'userEvents/CUSTOMIZE_FEED';
export const LOGIN_FROM_FORUM = 'userEvents/LOGIN_FROM_FORUM';
export const SELECT_FONT = 'userEvents/SELECT_FONT';
export const SELECT_FONT_SIZE = 'userEvents/SELECT_FONT_SIZE';
export const DELETE_CATEGORY = 'userEvents/DELETE_CATEGORY';
export const EDIT_CATEGORY = 'userEvents/EDIT_CATEGORY';
export const DISCARD_CHANGES_CATEGORY = 'userEvents/DISCARD_CHANGES_CATEGORY';
export const CREATE_CATEGORY = 'userEvents/CREATE_CATEGORY';
export const SHOW_SUBCATEGORIES = 'userEvents/SHOW_SUBCATEGORIES';
export const MAKE_CATEGORY_CHILD_OR_PARENT = 'userEvents/MAKE_CATEGORY_CHILD_OR_PARENT';
export const SET_POST_LIMIT = 'userEvents/SET_POST_LIMIT';
export const SHOW_EMPTY_STATE = 'userEvents/SHOW_EMPTY_STATE';
export const MOVE_POST = 'userEvents/MOVE_POST';
export const REPLY_OR_COMMENT_INTENT = 'userEvents/REPLY_OR_COMMENT_INTENT';
export const CLICK_PUBLISH = 'userEvents/CLICK_PUBLISH';
export const CLICK_LIKE = 'userEvents/CLICK_LIKE';

export const userEventsPickForumSubject = createAction(PICK_FORUM_SUBJECT);
export const userEventsChooseForumStructure = createAction(CHOOSE_FORUM_STRUCTURE);
export const userEventsCustomizeRCE = createAction(CUSTOMIZE_RCE);
export const userEventsShowFooterPosts = createAction(SHOW_FOOTER_POSTS);
export const userEventsShowHeader = createAction(SHOW_HEADER);
export const userEventsResizeHeader = createAction(RESIZE_HEADER);
export const userEventsCustomizeFeed = createAction(CUSTOMIZE_FEED);
export const userEventsLoginFromForum = createAction(LOGIN_FROM_FORUM);
export const userEventsSelectFont = createAction(SELECT_FONT);
export const userEventsSelectFontSize = createAction(SELECT_FONT_SIZE);
export const userEventsDeleteCategory = createAction(DELETE_CATEGORY);
export const userEventsEditCategory = createAction(EDIT_CATEGORY);
export const userEventsCreateCategory = createAction(CREATE_CATEGORY);
export const userEventsShowSubcategories = createAction(SHOW_SUBCATEGORIES);
export const userEventsMakeCategoryChildOrParent = createAction(MAKE_CATEGORY_CHILD_OR_PARENT);
export const userEventsSetPostLimit = createAction(SET_POST_LIMIT);
export const userEventsDiscardChangesCategory = createAction(DISCARD_CHANGES_CATEGORY);
export const userEventsShowEmptyState = createAction(SHOW_EMPTY_STATE);
export const userEventsMovePost = createAction(MOVE_POST);
export const userEventsReplyOrCommentIntent = createAction(REPLY_OR_COMMENT_INTENT);
export const userEventsClickPublish = createAction(CLICK_PUBLISH);
export const userEventsClickLike = createAction(CLICK_LIKE);
